import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          'JavaScript, React Js & React Native Developer',
          'Full-Stack Web & Mobile App Developer',
          'Generalist Developer, Not Just a Specialist',
          'Juggling Tech Stacks & Leading Teams',
          'Open to Exciting Opportunities!',
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
