import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify", lineHeight: "1.5", letterSpacing: "0.5px" }}>
            Hi there! I’m <span className="purple">Shreya Shet</span>, 
            a <span className="purple">Software Engineer</span> with over <span className="purple">2 years of experience</span> based in 
            <span className="purple"> Mumbai, India.</span>
            <br /> I’ve had the fun of building cool stuff for clients like <span className="purple">IIT Bombay </span> 
             and various <span className="purple">startups</span>. Whether it’s creating <span className="purple">full-stack web </span> or 
            <span className="purple"> mobile apps</span>, I’m all about turning complex problems into simple, scalable solutions.
            <br />
            At <span className="purple">SSingularity Technologies</span>, I lead teams, juggle tech stacks, and somehow keep 
            everyone on track (most of the time!). Proficient in <span className="purple">JavaScript</span>, <span className="purple">TypeScript</span>, <span className="purple">PHP</span>, 
            and <span className="purple">Ruby on Rails</span>, I’ve had a blast pushing changes to production on <span className="purple">Digital Ocean </span> 
             using <span className="purple">Docker</span>. Oh, and I also have a <span className="purple">Bachelor's in Computer Engineering</span>
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
